
import { proxyImageUrl } from '@/libs/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PreviewNewsBanner extends Vue {
    @Prop() posterUrl!: string;
    @Prop() picture!: string;
    @Prop() morning!: string;

    get getPicture() {
        return this.picture.indexOf('mmbiz.qpic.cn') > 0 ? this.picture : proxyImageUrl(this.picture);
        return proxyImageUrl(this.picture);
    }
}
